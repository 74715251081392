<template>
  <v-card :loading="loading">
    <v-card-title>
      <span class="headline"></span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="configForm">
          <v-row>
            <v-col>
              <v-text-field v-model="pod.name"
                            label="Pod Name"
                            required
                            :rules="nameRules">
              </v-text-field>              
            </v-col>
          </v-row>
          <v-row>
          <v-col>
            <v-text-field v-model="pod.description"
                          label="Pod Description"
                          required
                          :rules="descriptionRules">
            </v-text-field>
          </v-col>
          </v-row>
          
          <v-row>
            <v-col>
              <v-checkbox label="Float" v-model="pod.isFloat" v-show="false"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="onSave"> Save </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>

import Utils from "../../mixins/utils";
import { AddPod } from "@/components/Pods/mutations/add-pod.graphql";

export default {
  name: "AddPod",
  props: {
    
  },
  mixins:[Utils],
  components:{
    //
  },
  data: () => ({
    loading: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 150 || 'Name must be less than 100 characters',
    ],
    descriptionRules: [
      v => !!v || 'Description is required',
      v => v.length <= 150 || 'Description must be less than 255 characters',
    ],
    pod: {
      name: "",
      description: "",
      isActive: true,
      isFloat: false,
      createdByUserId: 0
    }
  }),
  methods: {
    async onSave() {
      if (!this.$refs.configForm.validate()) {
        await this.$dialog.error({
          text: 'Please fix the highlighted issues before savings',
          title: 'Unable to save',
          actions: ['Okay'],
        });

        return;
      }
      
      this.pod.createdByUserId = this.$store.state.achillesUser.userId;

      try {
        await this.$apollo.mutate(
            {
              mutation: AddPod,
              variables: {
                input: this.pod
              }
            });
        
        await this.$dialog.info({
          text: 'Pod saved successfully',
          title: 'Saved',
          actions: ['Okay']
        });

        this.$emit("saved");
        
      } catch (e) {
        console.log(e);
        await this.$dialog.error({
          text: 'Unable to save pod',
          title: 'Unable to save',
          actions: ['Okay']
        });
      }
    },
    onCancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    //
  },
  created() {
    //
  }
};
</script>

<style scoped lang="scss">

</style>