<template>
   <v-container>
      <v-dialog v-model="deleteConfirmDialog" persistent max-width="500" content-class="confirm-dialog" v-if="deleteConfirmDialog">
         <v-card>
            <v-card-title class="headline primary">
               Confirm Delete
            </v-card-title>
            <v-card-text>Are you sure you want to delete this Pod?</v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      
      <v-dialog v-model="addDialog" max-width="800px" persistent v-if="addDialog">
         <v-toolbar color="primary" dark>
            <v-btn dark icon @click="addDialog = false">
               <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Pod</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
               <v-btn dark text @click="onAddPodCanceled()"> Close</v-btn>
            </v-toolbar-items>
         </v-toolbar>
         <add-pod v-if="addDialog" @canceled="onAddPodCanceled()" @saved="onAddPodSaved()"></add-pod>
      </v-dialog>
      
      <v-dialog v-model="editDialog" max-width="800px" persistent v-if="editDialog">
         <v-toolbar color="primary" dark>
            <v-btn dark icon @click="editDialog = false">
               <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Pod</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
               <v-btn dark text @click="onEditPodCanceled()"> Close</v-btn>
            </v-toolbar-items>
         </v-toolbar>
         <edit-pod v-if="editDialog" :pod-data="selectedPod" @canceled="onEditPodCanceled()"
                   @saved="onEditPodSaved()"></edit-pod>
      </v-dialog>
      <v-card class="pa-3">
         <v-row>
            <v-col>
               <v-btn v-if="canAddPod" class="float-right primary" @click="addDialog = true">Add Pod</v-btn>
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <v-data-table v-if="podList"
                             :headers="headers"
                             :items="podItems"
                             :loading="loading || this.$apollo.loading"
                             class="elevation-1"
                             :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options':rowsPerPage}">
                  
                  <template #[`item.isFloat`]="{ item }">
                     {{item.isFloat ? "Yes" : "No"}}
                  </template>
                  <template #[`item.actions`]="{ item }">
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn v-if="canEditPod(item)" icon v-bind="attrs" v-on="on" class="mr-2" medium @click="showEdit(item)">
                              <v-icon>mdi-pencil</v-icon>
                           </v-btn>
                           <div class="v-btn--icon v-size--default mr-2 float-left" v-if="!canEditPod(item)"></div>
                        </template>
                        <span>Edit</span>
                     </v-tooltip>
                     
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn icon v-bind="attrs" v-on="on" class="mr-2" medium @click="displayAssignments(item.podId)">
                              <v-icon>mdi-badge-account-outline</v-icon>
                           </v-btn>
                        </template>
                        <span>Assignments</span>
                     </v-tooltip>
                     
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn icon v-bind="attrs" v-on="on" class="mr-2" medium @click="displayMembership(item.podId)">
                              <v-icon>mdi-account-group</v-icon>
                           </v-btn>
                        </template>
                        <span>Patients</span>
                     </v-tooltip>
                     
                     <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn v-if="canDeletePod()" icon v-bind="attrs" v-on="on" class="mr-2" medium @click="deletePodDialog(item.podId)">
                              <v-icon>mdi-delete</v-icon>
                           </v-btn>
                           <div class="v-btn--icon v-size--default mr-2 float-right" v-if="!canDeletePod()"></div>
                        </template>
                        <span>Delete</span>
                     </v-tooltip>
                  </template>
               
               </v-data-table>
            </v-col>
         </v-row>
      
      </v-card>
   
   </v-container>

</template>

<script>
   import {Input} from "@/types/queryInput";
   import GetPods from "@/components/Pods/queries/pod-list.graphql";
   import DeletePod from "./mutations/delete-pod.graphql";
   import Utils from "../../mixins/utils";
   import AddPod from "@/components/Pods/AddPod";
   import EditPod from "@/components/Pods/EditPod";
   import router from "@/router";
   import {PodAccessService} from "@/services/PodAccessService";
   
   export default {
      name: "PodList",
      props: {
         podAccess: PodAccessService
      },
      mixins: [Utils],
      components: {
         AddPod,
         EditPod
      },
      data: () => ({
         podList: [],
         currentPage: 1,
         loading: false,
         addDialog: false,
         selectedPod: {},
         selectedDeletePodId: null,
         editDialog: false,
         skipGetPodQuery: true,
         initialLoad: true,
         itemsPerPage: 100,
         podIdForDelete: -1,
         userPodAssignment: {},
         deleteConfirmDialog: false,
         headers: [
            {
               text: "Pod Name",
               align: "start",
               value: "name"
            },
            {
               text: "Description",
               value: "description"
            },
            {
               text: "Actions",
               value: "actions",
               align: "center",
               width: "20%"
            }
         ]
      }),
      methods: {
         triggerQuery() {
            if (!this.initialLoad) {
               return;
            }
            
            this.$apollo.queries.podList.skip = false;
            this.$apollo.queries.podList.refetch();
            
         },
         async deletePodDialog(podId) {
            this.selectedDeletePodId = podId;
            
            this.deleteConfirmDialog = true;
         },
         async confirmDialogCallback(agreed) {
            if (agreed) {
               await this.deletePod(this.selectedDeletePodId);
            }
            this.selectedDeletePodId = null;
            
            this.deleteConfirmDialog = false;
         },
         async deletePod(podId) {
            await this.$apollo.mutate({
                                         mutation: DeletePod,
                                         variables: {
                                            podId: podId,
                                            userId: this.$store.state.achillesUser.userId
                                         }
                                      }).then(result => {
               if (result.data.deletePod.error == true) {
                  this.$dialog.error({
                                        text: "All Users and Members must be removed prior to deleting a Pod.",
                                        title: "Error",
                                        actions: ["Okay"]
                                     });
               } else {
                  this.$apollo.queries.podList.skip = false;
                  this.initialLoad = true;
                  this.triggerQuery();
                  
                  this.$dialog.info({
                                       text: "Pod has been deleted.",
                                       title: "Pod Deleted",
                                       actions: ["Okay"]
                                    });
               }
            });
         },
         async onAddPodCanceled() {
            this.addDialog = false;
         },
         async onAddPodSaved() {
            this.addDialog = false;
            await this.$apollo.queries.podList.refetch();
         },
         async onEditPodCanceled() {
            this.editDialog = false;
         },
         async onEditPodSaved() {
            this.editDialog = false;
            await this.$apollo.queries.podList.refetch();
         },
         async showEdit(item) {
            this.selectedPod = item;
            this.editDialog = true;
         },
         displayMembership(podId) {
            router.push(
                {
                   name: "PodMemberships",
                   params: {podId: podId}
                });
         },
         displayAssignments(podId) {
            router.push(
                {
                   name: "PodAssignments",
                   params: {podId: podId}
                });
         },
        
         canEditPod(pod) {
            return this.podAccess && this.podAccess.canEditPod(pod.podId, pod.assignments);
         },
         canDeletePod(){
            return this.podAccess && this.podAccess.canDeletePod();
         }
      },
      computed: {
         canAddPod() {
           return this.podAccess && this.podAccess.canAddPod();
         },

         rowsPerPage() {
                return this.$rowsPerPage;
            },
        
         podItems() {
            return this.podList ? this.podList.data : [];
         },
         podInput() {
            let skip = this.itemsPerPage * (this.currentPage - 1);
            let input = new Input();
            input.take = this.itemsPerPage;
            input.skip = skip ? skip : 0;
            input.active = true;
            
            return input;
         },
         podAssignmentInput(){
            let input = new Input();
            input.userId = this.$store.state.achillesUser.userId;
            
            return input;
         }
      },
      apollo: {
         podList: {
            query: GetPods,
            variables() {
               return {
                  input: this.podInput,
                  podAssignmentInput: this.podAssignmentInput
               }
            },
            update(data) {
               this.initialLoad = false;
               return data.podList;
            },
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipGetPodQuery;
            }
         }
      },
      mounted() {
        this.triggerQuery();
      }
   };
</script>
