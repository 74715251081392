<template>
   <v-main>
      
      <pod-list v-if="podAccess" :podAccess="podAccess"></pod-list>
   </v-main>
</template>
<script>
   import PodList from "../components/Pods/PodList";
   import {PodAccessService} from "@/services/PodAccessService";
   
   export default {
      components: {
         PodList
      },
      data: () => ({
         podAccessServices: null
      }),
      computed: {
         podAccess() {
            if (!this.podAccessServices) {
               return null;
            }
            return this.podAccessServices;
         }
      },
      async created() {
         this.podId = null;
         
         let service = new PodAccessService(this.$store.state.achillesUser.userId, this.podId, this.$apollo);
         //get user pod role for action buttons
         await service.getUser()
                      .then(() => {
                         this.podAccessServices = service;
                         this.podAccessServices.setIsSuperAdmin();
                      });
         
      }
   };
</script>
